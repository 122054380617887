export function useCookie() {
  const defaultPath = window.location.pathname;

  function setCookie(name, value, expires = 1) {
    let cookieString = `${name}=${value || ''}; path=${defaultPath}`;

    if (expires) {
      const date = new Date();
      date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
      cookieString += `; expires=${date.toUTCString()}`;
    }

    document.cookie = cookieString;
  }

  function getCookie(name) {
    const cookieName = `${name}=`;
    const cookie = document.cookie.split('; ').find((row) => row.startsWith(cookieName));
    return cookie ? cookie.substring(cookieName.length) : null;
  }

  return { setCookie, getCookie };
}
