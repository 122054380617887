import { defineStore } from 'pinia';
import { computed, reactive, ref, defineAsyncComponent } from 'vue';
import router from '@/router/index.js';
import { API } from '@/api/index.js';
import { useAuthStore } from '@/stores/AuthStore.js';
import { useModalStore } from '@/stores/ModalStore';
import { useLoading } from '@/composables/useLoading.js';
import { serverErrorsHandler } from '@/helpers/utils.js';
import { useDataLayer } from '@/composables/useDataLayer.js';
import { useCookie } from '@/composables/useCookie';

import CookieConsentNotification from '@/components/shared/Popups/CookieConsentNotification.vue';

const defaultInputsState = {
  name: {
    key: 'name',
    value: '',
    error: '',
    serverError: '',
    valid: true,
    required: true,
  },
  email: {
    key: 'email',
    value: '',
    error: '',
    serverError: '',
    valid: true,
    required: true,
  },
  phone: {
    key: 'phone',
    value: '',
    error: '',
    serverError: '',
    help: true,
    valid: true,
  },
  message: {
    key: 'message',
    value: '',
    error: '',
    serverError: '',
    required: true,
    valid: true,
    textarea: true,
  },
};
export const useMainStore = defineStore('MainStore', () => {
  const { initGoogleAnalytics, setEventView } = useDataLayer();
  const auth = useAuthStore();
  const modals = useModalStore();
  const products = [
    {
      id: 'depression-test',
      title: 'home.yourProducts.list.depressionTest.title',
      text: 'home.yourProducts.list.depressionTest.text',
      lock: false,
      progress: 30,
      img: 'depression.png',
    },
    {
      id: 'mental-health',
      title: 'home.yourProducts.list.mentalhealth.title',
      text: 'home.yourProducts.list.mentalhealth.text',
      lock: true,
      progress: 0,
      img: 'mental.png',
    },
  ];
  const articles = ref([]);
  const showArticle = ref(null);
  const timeZones = [auth.inputs.profile.timezone];
  const languages = [
    { id: 'en', title: 'English' },
    { id: 'fr', title: 'French' },
  ];
  const usersMeta = ref(null);
  const inputs = reactive({
    support: {
      name: {
        key: 'name',
        value: '',
        error: '',
        serverError: '',
        valid: true,
        required: true,
      },
      email: {
        key: 'email',
        value: '',
        error: '',
        serverError: '',
        valid: true,
        required: true,
      },
      phone: {
        key: 'phone',
        value: '',
        error: '',
        serverError: '',
        help: true,
        valid: true,
      },
      message: {
        key: 'message',
        value: '',
        error: '',
        serverError: '',
        required: true,
        valid: true,
        textarea: true,
      },
    },
  });
  const { isLoading, setLoaderState } = useLoading();
  const { getCookie } = useCookie();

  const moods = ref(null);
  const faqContent = ref([
    {
      id: 1,
      name: 'General',
      questions: [
        {
          answer: '<p>answer</p>',
          category_id: 1,
          id: 3,
          question: 'Question Number 1',
        },
        {
          answer: '<p>answer</p>',
          category_id: 1,
          id: 1,
          question: 'Question Number 2',
        },
      ],
      slug: 'general',
    },
    {
      id: 2,
      name: 'Access',
      questions: [
        {
          answer: '<p>answer</p>',
          category_id: 1,
          id: 3,
          question: 'Question Number 1',
        },
        {
          answer: '<p>answer</p>',
          category_id: 1,
          id: 1,
          question: 'Question Number 2',
        },
      ],
      slug: 'access',
    },
    {
      id: 3,
      name: 'Payment',
      questions: [
        {
          answer: '<p>answer</p>',
          category_id: 1,
          id: 3,
          question: 'Question Number 1',
        },
        {
          answer: '<p>answer</p>',
          category_id: 1,
          id: 1,
          question: 'Question Number 2',
        },
      ],
      slug: 'payment',
    },
  ]);

  const device = computed(() => {
    let MobileUserAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (
      MobileUserAgent.match(/iPhone/i) ||
      MobileUserAgent.match(/iPod/i) ||
      MobileUserAgent.match(/iPad/i)
    ) {
      return 'iOS';
    } else if (MobileUserAgent.match(/Android/i)) {
      return 'Android';
    } else {
      return 'Desktop';
    }
  });

  const getMoods = async () => {
    try {
      const {
        data: { data },
        status,
      } = await API.base.getMoods();
      if (status === 200) moods.value = data;
    } catch (error) {
      console.error(error);
    }
  };

  const resetInputsState = () => {
    inputs.support = defaultInputsState;
  };
  const getFaqContent = async () => {
    try {
      const {
        data: { data },
        status,
      } = await API.base.getFaqContent();
      if (status === 200) faqContent.value = data;
    } catch (error) {
      console.error(error);
    }
  };
  const getArticlesContent = async () => {
    try {
      const {
        data: { data },
        status,
      } = await API.articles.getAll();
      if (status === 200) articles.value = data;
    } catch (error) {
      console.error(error);
    }
  };

  const newPayment = async (click_id) => {
    setLoaderState(true);
    const payload = { pay_type: 'sms', new_click_id: click_id };

    try {
      const {
        data: { data },
        status,
      } = await API.base.pay(payload);

      if (status === 200 || status === 204) {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setLoaderState();
    }
  };
  const getCurrentArticle = async (id) => {
    try {
      const {
        data: { data },
        status,
      } = await API.articles.getOne(id);
      if (status === 200) {
        showArticle.value = data;
        setEventView('article ' + data.id);
        router.push({ name: 'article', params: { id: data.id } });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendSupportMessage = async () => {
    setLoaderState(true);

    const payload = {};
    Object.keys(inputs.support).forEach((key) => {
      payload[key] = inputs.support[key].value;
    });

    try {
      const { status } = await API.base.sendSupportMessage(payload);

      if (status === 204) {
        resetInputsState();
        return true;
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 422) {
        serverErrorsHandler(error.response.data.errors, 'support', inputs);
      }
    } finally {
      setLoaderState();
    }
  };

  const updateUsersMeta = async (data) => {
    const payload = {
      click_id: auth.user.click_id,
      meta_data: {
        cohort_keyword: 'LLW',
      },
    };

    try {
      const { status } = await API.base.updateMeta(payload);

      if (status === 200) return true;
    } catch (error) {
      console.error(error);
    }
  };

  const getUsersMeta = async (click_id) => {
    const clickId = auth.user.click_id || click_id;

    if (!clickId) return;

    try {
      const { data, status } = await API.base.getMeta(clickId);
      if (status === 200) usersMeta.value = data.data;
    } catch (error) {
      console.error(error);
    }
  };

  const loadCookieConsent = () => {
    const isCookieConsentAccepted = getCookie('cookie_consent') === 'accepted';
    const noConsent = !getCookie('cookie_consent');

    if (isCookieConsentAccepted) {
      initGoogleAnalytics();
    } else if (noConsent) {
      setTimeout(() => {
        modals.openPopup({
          component: CookieConsentNotification,
          overlay: false,
          closeOnRouteChange: false,
        });
      }, 2000);
    }
  };

  return {
    device,
    products,
    articles,
    timeZones,
    languages,
    moods,
    faqContent,
    showArticle,
    isLoading,
    inputs,
    usersMeta,
    getFaqContent,
    getMoods,
    getArticlesContent,
    getCurrentArticle,
    sendSupportMessage,
    updateUsersMeta,
    getUsersMeta,
    newPayment,
    setLoaderState,
    loadCookieConsent,
  };
});
