<template>
  <div
    v-if="modals.popupState.overlay"
    class="overlay"
    @click.self="modals.closePopup"
    aria-modal="true"
    role="dialog"
    tabindex="-1"
  >
    <Transition name="slide">
      <component v-if="show" :is="modals.popupState.component" v-bind="modals.popupState.props" />
    </Transition>
  </div>

  <Transition v-else name="slide">
    <component v-if="show" :is="modals.popupState.component" v-bind="modals.popupState.props" />
  </Transition>
</template>

<script setup>
import { useModalStore } from '@/stores/ModalStore.js';
import { onMounted, ref } from 'vue';
const modals = useModalStore();

const show = ref(false);

onMounted(() => {
  setTimeout(() => {
    show.value = true;
  }, 200);
});
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: all 0.3s ease-out;
}

.slide-leave-active {
  transition: all 0.8s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(200px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(54, 58, 68, 0.4);
  z-index: 105;

  display: grid;
  place-items: center;

  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 899px) {
    place-items: flex-end center;
  }
}
</style>
