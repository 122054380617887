const AUTH_URLS = {
  login: '/api/v1/user/login',
  profile: '/api/v1/user/profile',
  register: '/api/v1/user/register',
  restorePassword: '/api/v1/user/forgot-password',
  resetPassword: '/api/v1/user/reset-password',
  socialiteLogin: '/api/v1/user/login/socialite/:socialite',
  socialiteRegister: '/api/v1/user/register/socialite/:socialite'
};

export default api => ({
  authorization: data => api.post(AUTH_URLS.login, data),
  getProfile: () => api.get(AUTH_URLS.profile),
  registration: data => api.post(AUTH_URLS.register, data),
  restorePassword: data => api.post(AUTH_URLS.restorePassword, data),
  updateProfile: data => api.patch(AUTH_URLS.profile, data),
  resetPassword: data => api.post(AUTH_URLS.resetPassword, data),
  socialiteLogin: ({ socialite, body }) => api.post(AUTH_URLS.socialiteLogin, body, { url_params: { socialite } }),
  socialiteRegister: ({ socialite, body }) => api.post(AUTH_URLS.socialiteRegister, body, { url_params: { socialite } })
});
